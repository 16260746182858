export const fengSettings = {
  name: 'stcerro',
  version: '0.0.1',
  hmr: true,
  language: 'es',
  currency: 'Gs',
  currencyExt1: 'symbol',
  currencyExt2: '3.0',
  projectName: 'Socio Cerro Porteño',
  clientName: 'Club Cerro Porteño',
  clientId: '',
  clientPhone: '+595 21 370090',
  clientEmail: 'dev@fengbrasil.com.br',
  clientAddress: 'Avenida 5ta c/ Tacuary, Asunción - Paraguai',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: {
    enableAdmin: true,
    enableSt: true,
    enableResale: false,
    enableCaptcha: true,
    verifyCPF: false,
    verifyEmail: true,
    keepConnected: true,
    admin: {
      applyTokenIn: ['auth']
    },
    loginForm: {
      title: 'Para acceder por primera vez al nuevo sistema, haga clic a continuación en Generar nueva contraseña para recibirla por correo electrónico.',
      text: 'Email/Documento',
      forgotPass: 'Generar nueva contraseña',
      forgotPassText: 'Complete su correo electrónico o documento registrado en Socio Feng y le enviaremos un código de recuperación. El correo electrónico sólo se enviará si se encuentra el registro.',
      forgotEmailText: 'Si has olvidado tu correo electrónico, por favor contacta con el servicio de atención al cliente <a href="/atendimento">haciendo clic aquí</a>.',
      forgotAccountLabel: 'E-mail/Documento',
      textLoginDocument: 'Email/Documento',
      forgotAccountDocumentLabel: 'E-mail/Documento',
      forgotPassDocumentText: 'Complete su correo electrónico o documento registrado en Socio Feng y le enviaremos un código de recuperación. El correo electrónico sólo se enviará si se encuentra el registro.'
    },
    firstAccess: {
      enable: false,
      migrationLogic: false,
      ask: {
        password: false,
        terms: true,
        missingData: true
      }
    },
    social: [
      { name: 'google', enabled: false },
      { name: 'facebook', enabled: false }
    ],
    register: {
      enableSt: true,
      showTerms: true,
      fields: {
        genre: false,
        clubId: false,
      },
      clubIdValidator: ''
    },
    staging: {
      enable: false,
      title: 'SEJA BEM-VINDO!',
      subtitle: '',
      description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
    },
  },
  payment: {
    creditCard: {
      maxAllowed: 3,
      allowedTypes: [
        { type: 'visa', regex: new RegExp('^4') },
        { type: 'mastercard', regex: new RegExp('^5[1-5]') },
        { type: 'american-express', regex: new RegExp('^3[47]') },
        { type: 'diners', regex: new RegExp('^30[0-5]') },
        { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
        { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
        { type: 'maestro', regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
        { type: 'discover', regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
        { type: 'elo', regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
        { type: 'hipercard', regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
      ],
      enableCaptcha: true,
      enableDelete: true
    },
    installments: {
      multiplePayment: {
        creditCardAllowed: true,
        bankSlipAllowed: false,
        pixAllowed: false,
      },
      subdivision: {
        creditCard: false,
      }
    },
    bancard: {
      enable: true
    }
  },
  loading: {
    global: 'ring', // default | ring | orbital | pulse
    local: 'bullets' // default | bars | bullets | progress
  },
  homeSettings: {
    scroll: {
      callToAction: {
        enable: true,
        template: 12,
        titleImage: '',
        titleText: ''
      },
      hero: false,
      tabs: {
        enable: true,
        template: 11
      },
      plans: {
        enable: true
      },
      specialPlans: {
        enabled: true
      },
      exclContent: false,
      testimony: false,
      callToActionFooter: false
    },
    cards: {
      enable: true,
      contentBox: true,
      experiences: false,
      plans: true,
      ticketCards: true,
      tickets: false,
      enableColorTheme: true
    },
    header: {
      legacyMsg: `Tu plan actual ha sido descontinuado. Haz clic abajo para obtener más información sobre los nuevos planes de Socio con más beneficios y ventajas.`,
      legacyBtnMsg: `CONOZCA NUESTROS NUEVOS PLANES`,
      templateHeader: 2,
      activeNewPlan: false,
      hasPendingContract: 'Existen pagos pendientes con su plan. Regularice para aprovechar todos los beneficios.'
    },
    plan: {
      dataInicialText: 'FECHA DE INICIO'
    },
    enableContractStatusWarning: true
  },
  myAccount: {
    home: {
      showBannerNewLink: true
    },
    menu: {
      plan: true,
      profile: true,
      payment: true,
      dependents: false,
      chairs: false
    },
    plan: {
      template: 2,
      enableMultiplePlans: true,
      hideAutomaticRenew: true,
      ticketCard: {
        showImage: false
      },
      customRenewText: true,
      renewButtonText: 'RENOVAR PAGO',
      renewDateText: 'PRÓXIMA RENOVACIÓN',
    },
    profile: {
      partnershipPeriod: {
        withoutInterruption: false,
        firstContract: true,
      },
      hidePartneshipPeriod: false,
      complementary: {
        enabled: true,
        fields: {
          nationality: true,
          rg: false,
          documentId: true,
          civilStatus: false,
          education: false,
          residentialPhone: false,
          shirtSize: false,
          shortSize: false,
          shoeSize: false,
          income: false,
          interest: false,
          matchFrequency: false,
          hasChildren: false,
          childrenNumber: false,
          profession: false,
          company: false,
          interestWorkField: false,
          club: false,
          religion: false
        },
        options: {
          shirtSize: [
            { value: 'P', label: 'P' },
            { value: 'M', label: 'M' },
            { value: 'G', label: 'G' },
            { value: 'GG', label: 'GG' },
            { value: '3G', label: '3G' },
            { value: '4G', label: '4G' },
            { value: '7G', label: '7G' },
          ]
        }
      }
    },
    ticketCards: {
      textWarn: {
        enableForm: false,
        textInfo: `<strong>ATENÇÃO</strong> - Ative sua carteirinha clicando no botão abaixo <strong>apenas</strong> se já tiver com ela em mãos para usufruir dos benefícios`
      }
    },
    socialNetworks: {
      enabled: true
    },
    address: {
      estadoMaxLength: 50,
      cepMaxLength: 20
    },
    phone: {
      minLength: 10
    },
    digitalProduct: {
      description: 'Regularice su plan para obtener descargas exclusivas',
    },
  },
  stCertificate: {
    template: 9,
    enable: true,
    showSTCertificate: true,
    imgBase: 'assets/CCP_Certificado_Socio.png',
  },
  partnersSettings: {
    enableMoreInfo: false,
  },
  experiencesSettings: {
    menu: {
      catalog: true,
      howToScore: true,
      points: true,
      rescued: true
    },
    enableSocioClub: false,
  },
  faqSettings: {
    faqTemplate: 10,
    mockFaq: false,
    contacts: {
      hide: true,
      chat: {
        active: false,
        availability: 'de segunda a sexta, das 8h às 21h, e aos sábados, das 10h às 16h',
      },
      email: {
        active: false
      }
    }
  },
  headerSettings: {
    headerTemplate: 14,
    floatingLogin: false,
    enableCounter: true,
    hideCounterOnPage: ['/transparencia'],
    enableMobileMenu: true,
    hidePoints: true,
    nav: [
      { title: 'HOME', routerLink: '/home', routerLinkActiveOptions: { exact: true } },
      { title: 'MI CUENTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      { title: 'PLANES', routerLink: '/planos', routerLinkActiveOptions: { exact: true } },
      { title: 'ENTRADAS', routerLink: `https://www.reduts.com.py/puntos-de-venta`, routerLinkActiveOptions: { exact: true }, click: 'externalurl' },
      { title: 'EXPERIENCIAS', routerLink: '/experiencias', routerLinkActiveOptions: { exact: true } },
      { title: 'PRODUCTOS DIGITALES', routerLink: '/productos-digitales', routerLinkActiveOptions: { exact: true } },
      { title: 'ATENCIÓN AL SOCIO', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
    ],
  },
  footerSettings: {
    footerTemplate: 1,
    social: [
      { icon: 'facebook', href: 'https://www.facebook.com/fengbrasil' },
      { icon: 'twitter', href: 'https://twitter.com/ECfengbrasil' },
      { icon: 'youtube', href: 'https://www.youtube.com/channel/UCrY4s3Eq7zSa5SE1LjoW_xg' },
      { icon: 'instagram', href: 'https://www.instagram.com/ecfengbrasil' }
    ],
    nav: [
      { title: 'HOME', routerLink: '/', click: '', clickParam: '', if: 'notHome' },
      { title: 'PLANES', routerLink: '/planos', click: '', clickParam: '', if: '' },
      { title: 'EXPERIENCIAS', routerLink: '/experiencias', click: '', clickParam: '', if: '' },
      { title: 'PRODUCTOS DIGITALES', routerLink: '/productos-digitales', click: '', clickParam: '', if: '' },
      { title: 'ATENCIÓN AL SOCIO', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
      { title: 'ASOCIARSE', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
      { title: 'SALIR', routerLink: '', click: 'logout', clickParam: '', if: 'isAuth' },
    ],
    enableTerms: true
  },
  plans: {
    template: 'fngst-2',
    especial: true,
    cardPlaceholder: "https://ngx-feng.s3-sa-east-1.amazonaws.com/images/loading/img-placeholder.png",
    enableDependents: false,
    enableInternationalPlans: true,
    subscribeText: 'ASOCIATE',
    defaultsubscribeText: 'EU QUERO',
    customSubscribeText: 'ASOCIATE',
    weInfo: true,
    disableOffState: true,
    registrationInClubeTitle: 'Atención!',
    registrationInClubeText: `<br>Para hacer el cambio de categoría, por favor comunicarse con atención al socio haciendo clic acá <a href="https://wa.me/595976775478" target="_blank">0976 775478</a><br><br>`,
    registrationInClube: true,
    tempUnavailableUpgradePlans: [
      'Teste',
      'ACTIVO INTERIOR PLATEA',
      'MÁS POPULAR GRADERÍA NORTE',
      'PREFERENCIA TESTE',
      'INFANTIL',
      'INTERNACIONAL GRADERÍA NORTE',
      'INTERNACIONAL PLATEA',
      'CADETE',
      'ABONO GRADERIA',
      'HONORARIO',
      'ABONO PLATEA',
      'ACTIVO CABALLERO',
      'ACTIVO DAMA',
      'INTERIOR ACTIVO C',
      'INTERIOR ACTIVO D',
      'VITALICIO',
      'ABONO PREFERENCIA',
      'INFANTIL GRADERÍA',
      'INFANTIL PREFERENCIA',
      'CADETE GRADERÍA',
      'INFANTIL PLATEA',
      'MÁS POPULAR PLATEA',
      'MÁS POPULAR PREFERENCIA',
      'ACTIVO INTERIOR GRADERÍA NORTE',
      'CADETE PLATEA',
      'ACTIVO PLATEA',
      'CADETE PREFERENCIA',
      'ACTIVO PREFERENCIA',
      'ACTIVO GRADERÍA NORTE',
      'REFINANCIACIÓN',
      'ACTIVO INTERIOR PREFERENCIA',
    ],
  },
  specialPlan: {
    template: 2,
  },
  xRay: {
    enableCounter: true,
    enableTabBackgroundColor: true,
    enableStates: false,
    enableCities: false,
    enableDistricts: false,
    enablePlans: true,
    enablePaymentMethod: true,
    enableAge: true,
    enableSex: true,
    enableXRay: true,
    enableXRayActionBackgroundColor: true,
    featuredTemplate: 2,
    enableFinancial: false,
    financialTemplate: 2,
    enableTransparency: false,
    tranparencyMonthsNameType: 'fullName', // fullName | shortName,
  },
  embassies: {
    template: 2
  },
  checkout: {
    register: {
      promptValidation: {
        cpf: false,
        email: false,
        clubId: false,
      },
      disableForeignCheckbox: true,
      disableCPF: true,
      enableDocument: true
    },
    defaultDuration: '12',
    maxGuests: true,
    showPaymentMethods: false,
    comingSoonPaymentSlip: false,
    modalSixPeriodicity: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
    },
    offState: {
      state: 'CENTRAL',
      states: ['CENTRAL', 'ASUNCIÓN'],
      modalOffState: {
        enabled: true,
        title: 'Atención',
        message: `
          Ha seleccionado un plan fuera de Gran Asunción, lo dirigiremos a la página de planes para seleccionar el plan correcto.
        `
      }
    },
    successPage: {
      enabled: true,
      title: '¡Pago procesado!',
      message: `¡Bienvenido a Socio Cerrista!`
    },
    boletoPage: {
      enabled: true,
      title: 'Boleto gerado com sucesso!',
      message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`
    },
    pixPage: {
      enabled: true,
      title: 'Código PIX gerado com sucesso',
      message: `Agora falta pouco! Pague a fatura gerada através do QR Code abaixo.`
    },
    paymentFailPage: {
      enabled: true,
      title: '¡Se produjo un error al procesar tu pago!',
      message: `No fue posible procesar tu pago con la forma de pago seleccionada..`
    },
    enableValidator: false,
    upgrade: {
      enableDiscountRule: true
    }
  },
  events: {
    shield: 'https://fengbrasil-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
    background: 'https://fengbrasil-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-ingressos-s.png',
    name: 'fengbrasil',
    template: 1,
    ticketing: 'SUPERINGRESSO', // Available Options: FCARD / MBILHETE / TICKETHUB / SUPERINGRESSO
    integration: {
      enableTicketBalance: false
    },
    enableHowManyTickets: true,
    showMyTickets: true,
    showMoreInfo: true,
    showTicketsClose: true,
    showRating: true,
    showHowManyTickets: true,
    imageMoreInformation: 'https://fluminensefc.s3.amazonaws.com/prod/assets/images/maisinfo-flu.png'
  },
  discountValidator: {
    nameType: 'firstName', // 'firstName' | 'fullName'
    returning: ['nome'] // nome, email, celular, cpf
  },
  virtualTicketCard: {
    template: 1,
    enable: false,
    showVirtualTicketCard: false,
    openVirtualTicketText: 'Visualizar Carteirinha Digital',
    imgLogo: {
      url: ''
    }
  },
  terms: {
    limitCardMessage: 'Si no tiene ese límite disponible en la tarjeta de crédito, solo se cobrará el valor de la cuota todos los meses de forma automática. Esto no altera ninguna condición prevista en los términos de uso.',
    splitted: false,
    termsURL: {
      url: null,
      route: '/termos-e-politicas',
      pageTitle: 'Términos de Uso',
      contentKeywords: ['termo', 'adesão']
    },
    privacyURL: {
      url: null,
      route: '/termos-e-politicas',
      pageTitle: 'Política de Privacidad',
      contentKeywords: ['termo', 'adesão']
    }
  },
  addressSettings: {
    stateList: [
      { sigla: 'Asunción', nome: 'Asunción', cities: [
        { sigla: 'ASUNCION', nome: 'ASUNCION' }
      ] },
      { sigla: 'Concepción', nome: 'Concepción' , cities: [
        { sigla: 'CONCEPCIÓN', nome: 'CONCEPCIÓN' },
        { sigla: 'SAN CARLOS', nome: 'SAN CARLOS' },
        { sigla: 'SAN LAZARO', nome: 'SAN LAZARO' },
        { sigla: 'YBY YAÚ', nome: 'YBY YAÚ' },
        { sigla: 'VALLEMI', nome: 'VALLEMI' },
        { sigla: 'HORQUETA', nome: 'HORQUETA' },
        { sigla: 'LORETO', nome: 'LORETO' },
        { sigla: 'BELÉN', nome: 'BELÉN' },
      ] },
      { sigla: 'San Pedro', nome: 'San Pedro', cities: [
        { sigla: 'ANTEQUERA', nome: 'ANTEQUERA' },
        { sigla: 'CAPI´IVARY', nome: 'CAPI´IVARY' },
        { sigla: 'CHORE', nome: 'CHORE' },
        { sigla: 'GRAL. ELIZARDO AQUINO', nome: 'GRAL. ELIZARDO AQUINO' },
        { sigla: 'GRAL. ISIDORO RESQUIN', nome: 'GRAL. ISIDORO RESQUIN' },
        { sigla: 'GUAYAIBI', nome: 'GUAYAIBI' },
        { sigla: 'ITACURUBI DEL ROSARIO', nome: 'ITACURUBI DEL ROSARIO' },
        { sigla: 'LIMA', nome: 'LIMA' },
        { sigla: 'NUEVA GERMANIA', nome: 'NUEVA GERMANIA' },
        { sigla: 'SAN ESTANISLAO (SANTANI)', nome: 'SAN ESTANISLAO (SANTANI)' },
        { sigla: 'SAN PABLO', nome: 'SAN PABLO' },
        { sigla: 'SAN PEDRO', nome: 'SAN PEDRO' },
        { sigla: 'SANTA ROSA DEL AGUARAY', nome: 'SANTA ROSA DEL AGUARAY' },
        { sigla: 'TACUATI', nome: 'TACUATI' },
        { sigla: 'UNIÓN', nome: 'UNIÓN' },
        { sigla: '25 DE DICIEMBRE', nome: '25 DE DICIEMBRE' },
        { sigla: 'VILLA DEL ROSARIO', nome: 'VILLA DEL ROSARIO' },
        { sigla: 'YATAITY DEL NORTE', nome: 'YATAITY DEL NORTE' },
        { sigla: 'YRYBUCUA', nome: 'YRYBUCUA' },
        { sigla: 'PUERTO ROSARIO', nome: 'PUERTO ROSARIO' }
      ]},
      { sigla: 'Cordillera', nome: 'Cordillera', cities: [
        { sigla: 'PIRARETÁ', nome: 'PIRARETÁ' },
        { sigla: 'ALTOS', nome: 'ALTOS' },
        { sigla: 'ARROYOS Y ESTEROS', nome: 'ARROYOS Y ESTEROS' },
        { sigla: 'ATYRA', nome: 'ATYRA' },
        { sigla: 'CAACUPÉ', nome: 'CAACUPÉ' },
        { sigla: 'CARAGUATAY', nome: 'CARAGUATAY' },
        { sigla: 'EMBOSCADA', nome: 'EMBOSCADA' },
        { sigla: 'EUSEBIO AYALA', nome: 'EUSEBIO AYALA' },
        { sigla: 'ISLA PUCÚ', nome: 'ISLA PUCÚ' },
        { sigla: 'ITACURUBÍ DE LA CORDILLERA', nome: 'ITACURUBÍ DE LA CORDILLERA' },
        { sigla: 'JUAN DE MENA', nome: 'JUAN DE MENA' },
        { sigla: 'LOMA GRANDE', nome: 'LOMA GRANDE' },
        { sigla: 'MBOCAYATY DEL YHAGUY', nome: 'MBOCAYATY DEL YHAGUY' },
        { sigla: 'NUEVA COLOMBIA', nome: 'NUEVA COLOMBIA' },
        { sigla: 'PIRIBEBUY', nome: 'PIRIBEBUY' },
        { sigla: 'PRIMERO DE MARZO', nome: 'PRIMERO DE MARZO' },
        { sigla: 'SAN BERNARDINO', nome: 'SAN BERNARDINO' },
        { sigla: 'SAN JOSÉ OBRERO', nome: 'SAN JOSÉ OBRERO' },
        { sigla: 'SANTA ELENA', nome: 'SANTA ELENA' },
        { sigla: 'TOBATÍ', nome: 'TOBATÍ' },
        { sigla: 'VALENZUELA', nome: 'VALENZUELA' },
      ] },
      { sigla: 'Guairá', nome: 'Guairá', cities: [
        { sigla: 'BORJA', nome: 'BORJA' },
        { sigla: 'CAPITÁN MAURICIO JOSÉ TROBHE', nome: 'CAPITÁN MAURICIO JOSÉ TROBHE' },
        { sigla: 'CNEL. MARTINEZ', nome: 'CNEL. MARTINEZ' },
        { sigla: 'DOCTOR BROTELL', nome: 'DOCTOR BROTELL' },
        { sigla: 'FÉLIX PÉREZ CARDOZO', nome: 'FÉLIX PÉREZ CARDOZO' },
        { sigla: 'GRAL. EUGENIO A. GARAY', nome: 'GRAL. EUGENIO A. GARAY' },
        { sigla: 'INDEPENDENCIA', nome: 'INDEPENDENCIA' },
        { sigla: 'ITAPÉ', nome: 'ITAPÉ' },
        { sigla: 'ITURBE', nome: 'ITURBE' },
        { sigla: 'JOSÉ A. FASSARDI', nome: 'JOSÉ A. FASSARDI' },
        { sigla: 'MBOCAYATY DEL GUAIRÁ', nome: 'MBOCAYATY DEL GUAIRÁ' },
        { sigla: 'NATALICIO TALAVERA', nome: 'NATALICIO TALAVERA' },
        { sigla: 'ÑUMI', nome: 'ÑUMI' },
        { sigla: 'PASO YOVAI', nome: 'PASO YOVAI' },
        { sigla: 'SAN SALVADOR', nome: 'SAN SALVADOR' },
        { sigla: 'VILLARRICA', nome: 'VILLARRICA' },
        { sigla: 'YATAITY DEL GUAIRÁ', nome: 'YATAITY DEL GUAIRÁ' }
      ] },
      { sigla: 'Caaguazú', nome: 'Caaguazú', cities: [
        { sigla: 'CAAGUAZÚ', nome: 'CAAGUAZÚ' },
        { sigla: 'CARAYAO', nome: 'CARAYAO' },
        { sigla: 'CORONEL OVIEDO', nome: 'CORONEL OVIEDO' },
        { sigla: 'DOCTOR CECILIO BÁEZ', nome: 'DOCTOR CECILIO BÁEZ' },
        { sigla: 'DOCTOR J. EULOGIO ESTIGARRIBIA', nome: 'DOCTOR J. EULOGIO ESTIGARRIBIA' },
        { sigla: 'DOCTOR JUAN MANUEL FRUTOS', nome: 'DOCTOR JUAN MANUEL FRUTOS' },
        { sigla: 'JOSÉ DOMINGO OCAMPOS', nome: 'JOSÉ DOMINGO OCAMPOS' },
        { sigla: 'LA PASTORA', nome: 'LA PASTORA' },
        { sigla: 'MCAL. FRANCISCO S. LÓPEZ', nome: 'MCAL. FRANCISCO S. LÓPEZ' },
        { sigla: 'NUEVA LONDRES', nome: 'NUEVA LONDRES' },
        { sigla: 'RAÚL ARSENIO OVIEDO', nome: 'RAÚL ARSENIO OVIEDO' },
        { sigla: 'REPATRIACIÓN', nome: 'REPATRIACIÓN' },
        { sigla: 'R.I. TRES CORRALES', nome: 'R.I. TRES CORRALES' },
        { sigla: 'SAN JOAQUÍN', nome: 'SAN JOAQUÍN' },
        { sigla: 'SAN JOSÉ DE LOS ARROYOS', nome: 'SAN JOSÉ DE LOS ARROYOS' },
        { sigla: 'SANTA ROSA DEL MBUTUY', nome: 'SANTA ROSA DEL MBUTUY' },
        { sigla: 'SIMÓN BOLIVAR', nome: 'SIMÓN BOLIVAR' },
        { sigla: '3 DE FEBRERO', nome: '3 DE FEBRERO' },
        { sigla: 'VAQUERÍA', nome: 'VAQUERÍA' },
        { sigla: 'YHÚ', nome: 'YHÚ' }
      ] },
      { sigla: 'Caazapá', nome: 'Caazapá', cities: [
        { sigla: 'ABAÍ', nome: 'ABAÍ' },
        { sigla: 'BUENA VISTA', nome: 'BUENA VISTA' },
        { sigla: 'CAAZAPA', nome: 'CAAZAPA' },
        { sigla: 'DOCTOR MOISES S. BERTONI', nome: 'DOCTOR MOISES S. BERTONI' },
        { sigla: 'FULGENCIO YEGROS', nome: 'FULGENCIO YEGROS' },
        { sigla: 'GRAL. HIGINIO MORÍNIGO', nome: 'GRAL. HIGINIO MORÍNIGO' },
        { sigla: 'MACIEL', nome: 'MACIEL' },
        { sigla: 'SAN JUAN NEPOMUCENO', nome: 'SAN JUAN NEPOMUCENO' },
        { sigla: 'TAVAÍ', nome: 'TAVAÍ' },
        { sigla: 'YUTY', nome: 'YUTY' },
        { sigla: 'ABAI', nome: 'ABAI' }
      ] },
      { sigla: 'Itapúa', nome: 'Itapúa', cities: [
        { sigla: 'BELLA VISTA (SUR)', nome: 'BELLA VISTA (SUR)' },
        { sigla: 'KAMBYRETA', nome: 'KAMBYRETA' },
        { sigla: 'CORONEL BOGADO', nome: 'CORONEL BOGADO' },
        { sigla: 'EDELIRA', nome: 'EDELIRA' },
        { sigla: 'FRAM', nome: 'FRAM' },
        { sigla: 'GRAL. ARTIGAS', nome: 'GRAL. ARTIGAS' },
        { sigla: 'GRAL. DELGADO', nome: 'GRAL. DELGADO' },
        { sigla: 'HOHENAU', nome: 'HOHENAU' },
        { sigla: 'ITAPUA POTY', nome: 'ITAPUA POTY' },
        { sigla: 'LA PAZ', nome: 'LA PAZ' },
        { sigla: 'NATALIO', nome: 'NATALIO' },
        { sigla: 'NUEVA ALBORADA', nome: 'NUEVA ALBORADA' },
        { sigla: 'OBLIGADO', nome: 'OBLIGADO' },
        { sigla: 'TRINIDAD', nome: 'TRINIDAD' },
        { sigla: 'YATYTAY', nome: 'YATYTAY' },
        { sigla: 'ALTO VERÁ', nome: 'ALTO VERÁ' },
        { sigla: 'CAPITÁN MEZA', nome: 'CAPITÁN MEZA' },
        { sigla: 'CAPITÁN MIRANDA', nome: 'CAPITÁN MIRANDA' },
        { sigla: 'CARLOS ANTONIO LÓPEZ', nome: 'CARLOS ANTONIO LÓPEZ' },
        { sigla: 'CARMEN DEL PARANÁ', nome: 'CARMEN DEL PARANÁ' },
        { sigla: 'ENCARNACIÓN', nome: 'ENCARNACIÓN' },
        { sigla: 'JESÚS', nome: 'JESÚS' },
        { sigla: 'JOSÉ LEANDRO OVIEDO', nome: 'JOSÉ LEANDRO OVIEDO' },
        { sigla: 'MAYOR JULIO D. OTAÑO', nome: 'MAYOR JULIO D. OTAÑO' },
        { sigla: 'PIRAPÓ', nome: 'PIRAPÓ' },
        { sigla: 'SAN COSME Y DAMIÁN', nome: 'SAN COSME Y DAMIÁN' },
        { sigla: 'SAN JUAN DEL PARANÁ', nome: 'SAN JUAN DEL PARANÁ' },
        { sigla: 'SAN RAFAEL DEL PARANÁ', nome: 'SAN RAFAEL DEL PARANÁ' },
        { sigla: 'TOMÁS ROMERO PEREIRA', nome: 'TOMÁS ROMERO PEREIRA' },
        { sigla: 'SAN PEDRO DEL PARANÁ', nome: 'SAN PEDRO DEL PARANÁ' }
      ] },
      { sigla: 'Misiones', nome: 'Misiones', cities: [
        { sigla: 'AYOLAS', nome: 'AYOLAS' },
        { sigla: 'SAN IGNACIO', nome: 'SAN IGNACIO' },
        { sigla: 'SAN JUAN BAUTISTA', nome: 'SAN JUAN BAUTISTA' },
        { sigla: 'SAN MIGUEL', nome: 'SAN MIGUEL' },
        { sigla: 'SAN PATRICIO', nome: 'SAN PATRICIO' },
        { sigla: 'SANTA MARÍA', nome: 'SANTA MARÍA' },
        { sigla: 'SANTA ROSA', nome: 'SANTA ROSA' },
        { sigla: 'SANTIAGO', nome: 'SANTIAGO' },
        { sigla: 'VILLA FLORIDA', nome: 'VILLA FLORIDA' },
        { sigla: 'YABEBYRY', nome: 'YABEBYRY' }
      ] },
      { sigla: 'Paraguarí', nome: 'Paraguarí', cities: [
        { sigla: 'ACAHAY', nome: 'ACAHAY' },
        { sigla: 'CAAPUCÚ', nome: 'CAAPUCÚ' },
        { sigla: 'CARAPEGUA', nome: 'CARAPEGUA' },
        { sigla: 'ESCOBAR', nome: 'ESCOBAR' },
        { sigla: 'GRAL. BERNARDINO CABALLERO', nome: 'GRAL. BERNARDINO CABALLERO' },
        { sigla: 'LA COLMENA', nome: 'LA COLMENA' },
        { sigla: 'MBUYAPEY', nome: 'MBUYAPEY' },
        { sigla: 'PARAGUARÍ', nome: 'PARAGUARÍ' },
        { sigla: 'PIRAY�', nome: 'PIRAY�' },
        { sigla: 'QUIINDY', nome: 'QUIINDY' },
        { sigla: 'QUYQUYHO', nome: 'QUYQUYHO' },
        { sigla: 'SAN ROQUE GONZÁLEZ DE SANTA CRUZ', nome: 'SAN ROQUE GONZÁLEZ DE SANTA CRUZ' },
        { sigla: 'SAPUCAI', nome: 'SAPUCAI' },
        { sigla: 'TEBICUARY-MI', nome: 'TEBICUARY-MI' },
        { sigla: 'YAGUARÓN', nome: 'YAGUARÓN' },
        { sigla: 'YBYCUÍ', nome: 'YBYCUÍ' },
        { sigla: 'YBYTYMÍ', nome: 'YBYTYMÍ' }
      ] },
      { sigla: 'Alto Paraná', nome: 'Alto Paraná', cities: [
        { sigla: 'CIUDAD DEL ESTE', nome: 'CIUDAD DEL ESTE' },
        { sigla: 'JUAN LEÓN MALLORQUÍN', nome: 'JUAN LEÓN MALLORQUÍN' },
        { sigla: 'DOMINGO MARTÍNEZ DE IRALA', nome: 'DOMINGO MARTÍNEZ DE IRALA' },
        { sigla: 'HERNANDARIAS', nome: 'HERNANDARIAS' },
        { sigla: 'IRUÑA', nome: 'IRUÑA' },
        { sigla: 'ITAKYRY', nome: 'ITAKYRY' },
        { sigla: 'JUAN EMILIO O´LEARY', nome: 'JUAN EMILIO O´LEARY' },
        { sigla: 'LOS CEDRALES', nome: 'LOS CEDRALES' },
        { sigla: 'MBARACAYÚ', nome: 'MBARACAYÚ' },
        { sigla: 'MINGA GUAZÚ', nome: 'MINGA GUAZÚ' },
        { sigla: 'MINGA PORÁ', nome: 'MINGA PORÁ' },
        { sigla: 'ÑACUNDAY', nome: 'ÑACUNDAY' },
        { sigla: 'NARANJAL', nome: 'NARANJAL' },
        { sigla: 'PRESIDENTE FRANCO', nome: 'PRESIDENTE FRANCO' },
        { sigla: 'SAN ALBERTO', nome: 'SAN ALBERTO' },
        { sigla: 'SAN CRISTÓBAL', nome: 'SAN CRISTÓBAL' },
        { sigla: 'SANTA ROSA DEL MONDAY', nome: 'SANTA ROSA DEL MONDAY' },
        { sigla: 'SANTA RITA', nome: 'SANTA RITA' },
        { sigla: 'YGUAZÚ', nome: 'YGUAZÚ' }
      ] },
      { sigla: 'Central', nome: 'Central', cities: [
        { sigla: 'AREGUÁ', nome: 'AREGUÁ' },
        { sigla: 'CAPIATÁ', nome: 'CAPIATÁ' },
        { sigla: 'FERNANDO DE LA MORA', nome: 'FERNANDO DE LA MORA' },
        { sigla: 'GUARAMBARÉ', nome: 'GUARAMBARÉ' },
        { sigla: 'ITÁ', nome: 'ITÁ' },
        { sigla: 'ITAUGUÁ', nome: 'ITAUGUÁ' },
        { sigla: 'JUAN AUGUSTO SALDIVAR', nome: 'JUAN AUGUSTO SALDIVAR' },
        { sigla: 'LAMBARÉ', nome: 'LAMBARÉ' },
        { sigla: 'LIMPIO', nome: 'LIMPIO' },
        { sigla: 'LUQUE', nome: 'LUQUE' },
        { sigla: 'MARIANO ROQUE ALONSO', nome: 'MARIANO ROQUE ALONSO' },
        { sigla: 'ÑEMBY', nome: 'ÑEMBY' },
        { sigla: 'NUEVA ITALIA', nome: 'NUEVA ITALIA' },
        { sigla: 'SAN ANTONIO', nome: 'SAN ANTONIO' },
        { sigla: 'SAN LORENZO', nome: 'SAN LORENZO' },
        { sigla: 'VILLA ELISA', nome: 'VILLA ELISA' },
        { sigla: 'VILLETA', nome: 'VILLETA' },
        { sigla: 'YPACARAI', nome: 'YPACARAI' },
        { sigla: 'YPANÉ', nome: 'YPANÉ' }
      ] },
      { sigla: 'Ñeembucú', nome: 'Ñeembucú', cities: [
        { sigla: 'ALBERDI', nome: 'ALBERDI' },
        { sigla: 'CERRITO', nome: 'CERRITO' },
        { sigla: 'DESMOCHADOS', nome: 'DESMOCHADOS' },
        { sigla: 'GRAL. JOSÉ EDUVIGIS DÍAZ', nome: 'GRAL. JOSÉ EDUVIGIS DÍAZ' },
        { sigla: 'GUAZÚ CUÁ', nome: 'GUAZÚ CUÁ' },
        { sigla: 'HUMAITÁ', nome: 'HUMAITÁ' },
        { sigla: 'ISLA UMBÚ', nome: 'ISLA UMBÚ' },
        { sigla: 'LAURELES', nome: 'LAURELES' },
        { sigla: 'MAYOR JOSÉ J. MARTÍNEZ', nome: 'MAYOR JOSÉ J. MARTÍNEZ' },
        { sigla: 'PASO DE PATRIA', nome: 'PASO DE PATRIA' },
        { sigla: 'PILAR', nome: 'PILAR' },
        { sigla: 'SAN JUAN BAUTISTA DEL ÑEEMBUCÚ', nome: 'SAN JUAN BAUTISTA DEL ÑEEMBUCÚ' },
        { sigla: 'TACUARAS', nome: 'TACUARAS' },
        { sigla: 'VILLA FRANCA', nome: 'VILLA FRANCA' },
        { sigla: 'VILLALBÍN', nome: 'VILLALBÍN' },
        { sigla: 'VILLA OLIVA', nome: 'VILLA OLIVA' }
      ] },
      { sigla: 'Amambay', nome: 'Amambay', cities: [
        { sigla: 'BELLA VISTA (NORTE)', nome: 'BELLA VISTA (NORTE)' },
        { sigla: 'CAPITÁN BADO', nome: 'CAPITÁN BADO' },
        { sigla: 'PEDRO JUAN CABALLERO', nome: 'PEDRO JUAN CABALLERO' }
      ] },
      { sigla: 'Canindeyú', nome: 'Canindeyú', cities: [
        { sigla: 'CORPUS CHRISTI', nome: 'CORPUS CHRISTI' },
        { sigla: 'GRAL. FRANCISCO CABALLERO ALVAREZ', nome: 'GRAL. FRANCISCO CABALLERO ALVAREZ' },
        { sigla: 'ITANARÁ', nome: 'ITANARÁ' },
        { sigla: 'KATUETÉ', nome: 'KATUETÉ' },
        { sigla: 'LA PALOMA', nome: 'LA PALOMA' },
        { sigla: 'NUEVA ESPERANZA', nome: 'NUEVA ESPERANZA' },
        { sigla: 'SALTO DEL GUAIRÁ', nome: 'SALTO DEL GUAIRÁ' },
        { sigla: 'VILLA SAN ISIDRO DE CURUGUATY', nome: 'VILLA SAN ISIDRO DE CURUGUATY' },
        { sigla: 'VILLA YGATIMÍ', nome: 'VILLA YGATIMÍ' },
        { sigla: 'YPEJHÚ', nome: 'YPEJHÚ' }
      ] },
      { sigla: 'Presidente Hayes', nome: 'Presidente Hayes', cities: [
        { sigla: 'BENJAMÍN ACEVAL', nome: 'BENJAMÍN ACEVAL' },
        { sigla: 'NANAWA', nome: 'NANAWA' },
        { sigla: 'POZO COLORADO', nome: 'POZO COLORADO' },
        { sigla: 'PUERTO PINASCO', nome: 'PUERTO PINASCO' },
        { sigla: 'VILLA HAYES', nome: 'VILLA HAYES' },
        { sigla: 'JOSÉ FALCÓN', nome: 'JOSÉ FALCÓN' },
        { sigla: 'TENIENTE IRALA FERNÁNDEZ', nome: 'TENIENTE IRALA FERNÁNDEZ' }
      ] },
      { sigla: 'Boquerón', nome: 'Boquerón', cities: [
        { sigla: 'BAHÍA NEGRA', nome: 'BAHÍA NEGRA' },
        { sigla: 'FUERTE OLIMPO', nome: 'FUERTE OLIMPO' },
        { sigla: 'LA VICTORIA (PUERTO CASADO)', nome: 'LA VICTORIA (PUERTO CASADO)' },
        { sigla: 'MAYOR PABLO LAGERENZA', nome: 'MAYOR PABLO LAGERENZA' },
        { sigla: 'TTE. CNEL. CARMELO PERALTA (ISL A MARGARITA(', nome: 'TTE. CNEL. CARMELO PERALTA (ISL A MARGARITA(' }
      ] },
      { sigla: 'Alto Paraguay', nome: 'Alto Paraguay', cities: [
        { sigla: 'DOCTOR PEDRO P. PEÑA', nome: 'DOCTOR PEDRO P. PEÑA' },
        { sigla: 'GRAL. EUGENIO A. GARAY', nome: 'GRAL. EUGENIO A. GARAY' },
        { sigla: 'MCAL. JOSÉ FÉLIX ESTIGARRIBIA', nome: 'MCAL. JOSÉ FÉLIX ESTIGARRIBIA' },
        { sigla: 'FILADELFIA', nome: 'FILADELFIA' },
        { sigla: 'NEULAND', nome: 'NEULAND' },
        { sigla: 'LOMA PLATA', nome: 'LOMA PLATA' },
        { sigla: 'COLONIA FERNHEIM', nome: 'COLONIA FERNHEIM' }
      ] }
    ],
    countryList: [
      { sigla: 'PARAGUAY', nome: 'PARAGUAY' },
      { sigla: 'ECUADOR', nome: 'ECUADOR' },
      { sigla: 'BELGICA', nome: 'BELGICA' },
      { sigla: 'ARGENTINA', nome: 'ARGENTINA' },
      { sigla: 'BRASIL', nome: 'BRASIL' },
      { sigla: 'CHILE', nome: 'CHILE' },
      { sigla: 'PERU', nome: 'PERU' },
      { sigla: 'CANADA', nome: 'CANADA' },
      { sigla: 'ALEMANIA', nome: 'ALEMANIA' },
      { sigla: 'ESPAÑA', nome: 'ESPAÑA' },
      { sigla: 'ESTADOS UNIDOS', nome: 'ESTADOS UNIDOS' },
      { sigla: 'FRANCIA', nome: 'FRANCIA' },
      { sigla: 'RUSIA', nome: 'RUSIA' },
      { sigla: 'INGLESA', nome: 'INGLESA' },
      { sigla: 'BOLIVIA', nome: 'BOLIVIA' },
      { sigla: 'URUGUAY', nome: 'URUGUAY' },
      { sigla: 'MEXICO', nome: 'MEXICO' },
      { sigla: 'SUIZA', nome: 'SUIZA' },
      { sigla: 'AFRICA DEL SUR', nome: 'AFRICA DEL SUR' },
      { sigla: 'AUSTRIA', nome: 'AUSTRIA' },
      { sigla: 'CHINA', nome: 'CHINA' },
      { sigla: 'AUSTRALIA', nome: 'AUSTRALIA' },
      { sigla: 'NO CODIF.', nome: 'OTRO' },
    ]
  }
}
